import {HttpHeaders} from '@angular/common/http'
import {preventBgoProprietaryHttpInterceptorHeader} from '../http/prevent.interceptor'
import {ApolloLink} from '@apollo/client/core'
import {HttpLink} from 'apollo-angular/http'
import {ConfigService} from '../config/config.service'
import {print, stripIgnoredCharacters} from 'graphql'
import {CMS_GRAPHQL_CLIENT_NAME} from '../content/content-graphql-split'
import {setContext} from '@apollo/client/link/context'
import {Injectable} from '@angular/core'

@Injectable({providedIn: 'root'})
export class GraphqlLinkFactory {
  constructor(
    private readonly configService: ConfigService,
    private readonly httpLink: HttpLink,
  ) {}

  build(getJwtToken?: () => Promise<string | undefined>): ApolloLink {
    const auth = this.authLink(getJwtToken)
    return this._build(auth)
  }

  private _build(auth?: ApolloLink): ApolloLink {
    const datoCmsApi = this.datoCmsApiLink()
    const buildigoApi = this.coreGraphqlApiLink(auth)

    // split traffic between CMS and our backend API depending on the client name
    return ApolloLink.split(
      op => [CMS_GRAPHQL_CLIENT_NAME].includes(op.getContext().clientName),
      datoCmsApi,
      buildigoApi,
    )
  }

  private authLink(getJwtToken?: () => Promise<string | undefined>) {
    if (!getJwtToken) {
      return undefined
    }
    return setContext(() => withAuthHeaders(getJwtToken))
  }

  private coreGraphqlApiLink(authLink?: ApolloLink): ApolloLink {
    const buildigoApi = this.httpLink.create({
      uri: this.configService.config.apiUrl,
      headers: new HttpHeaders({
        // manually set these headers for this link, because other links may not accept these headers in their CORS policy
        'apollographql-client-name': 'bgo-web-ui',
        'apollographql-client-version': this.configService.config.version,
      }),
      // override default behavior (print) to strip out unnecessary characters, makes queries smaller
      operationPrinter: documentNode => stripIgnoredCharacters(print(documentNode)),
    })
    let buildigoLink: ApolloLink[] = [buildigoApi]
    if (authLink) {
      buildigoLink = [authLink, buildigoApi]
    }

    return ApolloLink.from(buildigoLink)
  }

  private datoCmsApiLink() {
    const {apiToken, preview, environment} = this.configService.config.datocms
    return this.httpLink.create({
      uri: `https://graphql.datocms.com/`,
      headers: new HttpHeaders({
        ...preventBgoProprietaryHttpInterceptorHeader,
        Authorization: `Bearer ${apiToken}`,
        ...(preview ? {'X-Include-Drafts': 'true'} : undefined),
        ...(environment ? {'X-Environment': environment} : undefined),
      }),
      // override default behavior (print) to strip out unnecessary characters, makes queries smaller
      // this helps with APIs query size limit
      // https://www.contentful.com/developers/docs/references/graphql/#/introduction/query-size-limits
      // https://www.apollographql.com/docs/react/networking/advanced-http-networking/
      operationPrinter: documentNode => stripIgnoredCharacters(print(documentNode)),
    })
  }
}

export async function withAuthHeaders(getJwtToken: () => Promise<string | undefined>) {
  const bearerToken = await getJwtToken()
  if (bearerToken) {
    return {headers: {Authorization: 'Bearer ' + bearerToken}}
  } else {
    return {}
  }
}
